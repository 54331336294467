<template>
	<div id="userlogin">
		<div class="loginbox" v-loading="codeType==2&&loading">
		  <div class="titlebox">
				<div class="text" :class="{'active':guardName=='api'}" @click="changeGuard('api')">管理员登录</div>
				<div class="text" :class="{'active':guardName=='orange_admin_user'}" @click="changeGuard('orange_admin_user')">员工登录</div>
				<div class="text" :class="{'active':guardName=='oa_partner_accounts'}" @click="changeGuard('oa_partner_accounts')">合作商登录</div>
		  </div>
		  <div class="radiobox">
			  <img @click="changeType()" v-if="codeType==1" class="radioicon" src="@/assets/icon-loginCodeWechat.png">
			  <img @click="changeType()" v-if="codeType==2" class="radioicon" src="@/assets/icon-loginAccount.png">
		  </div>
		  <div class="codebox" v-if="codeType==2">
				<div class="accountList" v-if="accountShow">
					<div class="box" v-for="(item,index) in accountList" :key="index">
						<img class="icon" src="@/assets/login/logo.png">
						<div class="text">{{item.shop_name}}</div>
						<el-button type="primary" size="small" @click="accountLogin(item)">登录</el-button>
					</div>
				</div>
				<div v-else style="display: flex;justify-content: center;height: 330px;">
					<div id="wechatCodeBox" style="height: 330px;overflow: hidden;"></div>
				</div>
		  </div>
		  
		  <div v-if="codeType==1">
				<div class="inputbox">
					<div class="box">
						<el-input ref="account" v-model="phone" :disabled="loading" type="text" class="input" auto-complete="off"
							:placeholder="`请输入${this.guardName=='oa_partner_accounts'?'合作商':this.guardName=='orange_admin_user'?'员工':'管理员'}账号`" 
						/>
					</div>
			    </div>
			    <div class="inputbox">
					<div class="box">
						<el-input ref="password" v-model="password" :disabled="loading" type="password" class="input" autocomplete="new-password" placeholder="请输入密码" />
					</div>
			    </div>
			   <div class="inputbox codebox">
			      <div class="box">
			        <el-input v-model="code" type="text" style="width: 400px;" class="input" placeholder="请输入验证码" />
			        <el-button v-if="!codeshow" type="primary" :loading="codeLoading" class="codeimg"  @click="getcode">获取验证码</el-button>
			        <div v-else class="codeimg success">{{ codetime }}秒后获取</div>
			      </div>
			    </div>
			  
			    <el-button class="loginbtn" :loading="loading"  style="width:100%;margin-bottom:30px;background-color: #1989fa;" size="mini" @click.native.prevent="passwordLogin()">登 录</el-button>
		  </div>
		  
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util';
import {resetRouter} from '@/router/index.js'
import {timLogin} from '@/util/tim.js'
import { partnerRoute } from '@/router/partnerRoute.js'
export default {
	name: 'loginComponents',
	data() {
		return {
			phone: '',
			password: '',
			code: '',
			codeshow: false,
			codetime: 59,
			loading:false,
			codeType:1,
			accountList:[],
			accountShow:false,
			codeLoading:false,
		};
	},
	computed:{
		wechatCode(){
			return this.$store.state.wechatCode
		}
	},
	watch:{
		wechatCode:{
			immediate:true,
			handler(code){
				if(code){
					this.codeType = 2
					this.$nextTick(()=>{
						this.getWechatCode()
						this.wechatLogin(code)
					})
				}
			}
		}
	},
	props:{
		guardName:{
			type:String,
			default:'api'
		}
	},
	methods: {
		async getMacAddress(){
			return new Promise((resolve)=> {
				fetch('http://localhost:5000/get-mac-address').then(response=>{
					if (!response.ok) {
						resolve(false)
					    throw new Error('Network response was not ok');
					}
					return response.json(); // 将响应解析为 JSON 格式
				}).then(data=>{
					resolve(data.mac_address)
				}).catch((err)=>{
					resolve(false)
				})
			})
		},
		changeGuard(guardName){
			if(this.guardName == guardName) return;
			this.$emit('update:guardName',guardName)
			this.$nextTick(()=>{
				if(this.codeType==2){
					this.getWechatCode()
				}
			})
		},
		changeType(){
			if(this.codeType==1){
				this.codeType = 2
				this.$nextTick(()=>{
					if(!this.accountShow) this.getWechatCode()
				})
			}else{
				this.codeType = 1
			}
		},
		getWechatCode(){
			new WxLogin({
				id:"wechatCodeBox",
				appid: "wxde370f4e7e986f83", 
				scope: "snsapi_login", 
				state:`loginCode=${this.guardName}`,
				redirect_uri: encodeURI(`https://admin.rltsaas.com/login?shopId=${sessionStorage.getItem('shopId')}&guardName=${this.guardName}`),
				fast_login:0,
				href:'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIzMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMzBweDt9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9Ci5pbXBvd2VyQm94IC5zdGF0dXMgcDpudGgtY2hpbGQoMikge2Rpc3BsYXk6IG5vbmU7fQ=='
			});
			console.log(`https://admin.rltsaas.com/login?shopId=${sessionStorage.getItem('shopId')}&guardName=${this.guardName}`)
			console.log(`loginCode=${this.guardName}`)
		},
		//微信登录
		wechatLogin(code){
			this.loading = true
			sessionStorage.setItem('guardName',this.guardName)
			this.axios.post('/api/oa/WxOpen/Login',{code:code,shop_id:sessionStorage.getItem('shopId')}).then(res=>{
				if(res.code==200){
					if(res.data.length==1){
						this.$store.commit('changeWechatCode', null)
						sessionStorage.setItem('token', res.data[0].token);
						this.getInfo(this.guardName);
					}else{
						this.loading = false
						this.accountList = res.data
						this.accountShow = true
					}
				}else{
					this.loading = false
					eltips(res.msg,'error')
				}
			})
		},
		//选择账号登录
		accountLogin(item){
			this.loading = true
			this.$store.commit('changeWechatCode', null)
			sessionStorage.setItem('token', item.token);
			this.getInfo(this.guardName);
		},
		//登录
		passwordLogin(){
			sessionStorage.setItem('guardName',this.guardName)
			if(this.guardName == 'api') this.login();
			if(this.guardName == 'orange_admin_user') this.adminLogin();
			if(this.guardName == 'oa_partner_accounts') this.partnerLogin();
		},
		async login() {
			this.loading = true
			let data = {
				phone: this.phone,
				password:this.password,
				type: 1,
				guard_name:'api',
				is_customer:1,
				sms_code:this.code,
				shop_id:sessionStorage.getItem('shopId')
			};
			let macAddress = await this.getMacAddress()
			if(macAddress) data.mac = macAddress
			this.axios.post('/api/login', data).then(res => {
				if (res.code == 200) {
					sessionStorage.setItem('token', res.data.access_token);
					this.getInfo('api');
				}else {
					this.loading = false
					eltips(res.msg, 'error');
				}
			});
		},
		adminLogin(){
			let data={
				username:this.phone,
				password:this.password,
				sms_code:this.code,
				shop_id:sessionStorage.getItem('shopId'),
			}
			
			this.loading = true
			this.axios.post('/api/orange_manage/auth/login',data).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('token',res.data)
					this.getInfo('orange_admin_user')
				}else{
					this.loading = false
					eltips(res.msg,'error')
				}
			})
		},
		partnerLogin(){
			let data = {
				username: this.phone,
				password:this.password,
				sms_code:this.code,
				shop_id:sessionStorage.getItem('shopId'),
			};
			this.loading = true
			this.axios.post('/api/oa/Partner/auth', data).then(res => {
				if (res.code == 200) {
					sessionStorage.setItem('token', res.data);
					this.getInfo('oa_partner_accounts');
				} else {
					this.loading = false
					eltips(res.msg, 'error');
				}
			});
		},
		//获取信息
		getInfo(guard) {
			this.axios.get('/api/me',{params:{guard_name:guard}}).then(res => {
				if (res.code == 200) {
					sessionStorage.setItem('userinfo', JSON.stringify(res.data));
					this.getimtoken(guard)
					if(guard=='oa_partner_accounts'){
						this.getPartnerRouter()
					}else{
						this.getmeuns();
					}
				} else {
					this.loading = false
					sessionStorage.removeItem('token');
					eltips(res.msg, 'error');
				}
			});
		},
		getPartnerRouter(){
			resetRouter()
			this.$store.commit('changeRouteadds', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			this.loading = false
			eltips('登录成功','success')
			const newPartnerRoute = JSON.stringify(partnerRoute)
			sessionStorage.setItem('routes',newPartnerRoute)
			this.$router.replace('/index')
		},
		// 获取im的token
		getimtoken(guard){
			this.axios.get('/api/orange_manage/user/imUserSig',{params:{guard_name:guard}}).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('IMtoken',res.data)
					if(!this.$store.sdk_ready) timLogin();
				}
			})
		},
		//获取菜单
		getmeuns(){
			this.axios.get('/api/get_menu').then(res=>{
				if(res.data.length==0){
					this.loading = false
					eltips('此账号尚未添加可用菜单，请联系管理员','error')
					sessionStorage.removeItem('token')
					sessionStorage.removeItem('userinfo')
					return
				}
				
				resetRouter()
				this.$store.commit('changeRouteadds', 0)
				sessionStorage.removeItem('routestext')
				sessionStorage.removeItem('routes')
				let toRouter = this.getPath(res.data)
				if(toRouter){
					this.loading = false
					eltips('登录成功','success')
					sessionStorage.setItem('routes',JSON.stringify(res.data))
					this.$router.replace(toRouter).catch(err=>{})
				}else{
					sessionStorage.removeItem('token')
					sessionStorage.removeItem('userinfo')
					eltips('菜单设置有误，请联系管理员重新设置','error')
				}
				
			})
		},
		getPath(list){
			let path,props,toRouter
			for(let i=0;i<list.length;i++){
				let item = list[i]
				if(item.path){
					path = item.path
					props = item.props||null
					toRouter = {'path':path}
					if(props) toRouter.query = {'props':props}
					return toRouter
				}
				if(item._child.length>0){
					return this.getPath(item._child)
				}
			}
			return false
		},
		//获取验证码
		getcode() {
			sessionStorage.setItem('guardName',this.guardName)
			if(!this.phone){
				eltips('请输入账号','error')
				return false
			}
			
			let data = {
				account: this.phone,
				shop_id:sessionStorage.getItem('shopId')
			}
			this.codeLoading = true
			this.axios.post('/api/send_code/public',data) .then(res => {
				if (res.code == 200) {
					eltips(res.msg, 'success');
					this.codeshow = true;
					this.codeLoading = false
					let autocode = setInterval(() => {
						if (this.codetime > 1) {
							this.codetime--;
						} else {
							this.codeshow = false;
							this.codetime = 59;
							clearInterval(autocode);
						}
					}, 1000);
				} else {
					this.codeLoading = false
					eltips(res.msg, 'error');
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
	.loginbox {
		border-radius: 12px;
		overflow: hidden;
		padding: 30px 30px;
		background: rgba(255, 255, 255, 0.8);
		width: 400px;
		position: relative;
		.radiobox{
			position: absolute;
			right: 0;
			top: 0;
			width: 0;      
			height: 0;      
			border-top: 90px solid rgb(25, 137, 250);
			border-left: 90px solid transparent;
		}
		.radioicon{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 5px;
			top: -80px;
			cursor: pointer;
		}
		.titlebox {
			text-align: center;
			display: flex;
			align-items: flex-start;
			gap: 16px;
			.text{
				cursor: pointer;
				font-size: 15px;
				color: #333;
			}
			.active{
				font-weight: bold;
				color: #1989fa;
				padding-bottom: 4px;
				border-bottom: 2px solid #1989fa;
			}
		}
		.inputbox {
			margin-top: 30px;
			.box {
				position: relative;
				display: flex;
				justify-content: space-between;
				// padding: 0 20px;
				// background: #e8e8e8;
				.codeimg {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 130px;
					text-align: center;
					background: #1989fa;
					color: #ffffff;
					border-radius: 4px;
					cursor: pointer;
					margin-left: 10px;
				}
				.success {
					background: rgba(255, 255, 255, 0) !important;
					color: #999999;
					border: 1px solid #999999;
					cursor: default;
				}
			}
			.error {
				color: #ff0000;
				font-size: 18px;
				margin-top: 8px;
			}
		}
		.loginbtn {
			margin-top: 40px;
			width: 100%;
			line-height: 34px;
			text-align: center;
			font-size: 16px;
			color: #ffffff;
			cursor: pointer;
			-moz-user-select: none; /*火狐*/
			-webkit-user-select: none; /*webkit浏览器*/
			-ms-user-select: none; /*IE10*/
			-khtml-user-select: none; /*早期浏览器*/
			user-select: none;
		}
	}
	
	.accountList{
		height: 330px;
		overflow-y: auto;
		.box{
			display: flex;
			align-items: center;
			padding:12px 0;
			border-bottom: 1px solid #c3c3c3;
			&:last-child{
				border-bottom:none;
			}
			.icon{
				flex: 0 0 28px;
				width: 28px;
				height: 28px;
				margin-right: 18px;
			}
			.text{
				flex: 1;
				font-size:16px;
				margin-right: 18px;
			}
			
		}
	}
	
</style>
